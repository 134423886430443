import useSWR from "swr/immutable";
import axios from "axios";
import { HOST } from "@/strings";
import { Cabang } from "@/types";
import useToken from "@/helpers/useToken";

const URL = HOST + "/cabang/";

const fetcher = async (_: string, authorization: any) =>
  authorization &&
  axios.get(URL, { headers: { authorization } }).then((res) => res.data.values?.data);

export const useCabang = () => {
  const token = useToken();
  const { data, mutate, error } = useSWR(token ? [URL, token] : null, fetcher);

  const loading = !data && !error;

  const payload = data as Cabang[];

  return {
    loading,
    error,
    data: payload,
    mutate,
  };
};

export default useCabang;
